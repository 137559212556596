import content from '../../data/page-content/mind.json'
import Button from "../button";
import bg from "../../data/bg-Logo.png"
import { Title, CopyRight, Accessibility, ContentList } from "../const"
import NavBar from '../navbar'



const Mind = ({ images }) => {
    const { list } = content

    return (
        <div id="mind" className="ctxt-mind fstxt-s">
            <NavBar bgColor="mind" btnColor="life"/>
            <div id="mind" className="max-srceen-height bg-img" style={{backgroundImage: `url(${bg})`}}>
                <section id="hero" className="light-bg content-center mh-95">
                    <Title type="mind">{content.title}</Title>
                    <p className="txt-center">{content.hashtag}</p>

                    <div className="grid gap-1 p-1 col-2 mt3">
                        <img src={images.mindsetA} alt="mindset" className="max-width"/>

                        <Button color="mind" className="auto-margin txt-center ctxt-primary w-maxium-4 fstxt-m" pos="content-center">
                            <h3>{list.title}</h3>
                            <ContentList content={list.items} listclass="no-bullets"/>
                        </Button>
                    </div>
                </section>
            </div>

            <CopyRight bgClass="mind" txtClass="ctxt-primary"/>
            <Accessibility bgClass="mind" className="auto-margin ctxt-primary p-1"/>
        </div>
    )
}


export default Mind