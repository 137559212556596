import data from '../../data/page-content/terms.json'
import { CopyRight, SubHeading, Content, Accessibility, 
    ContentList, ContentSection } from '../const'
import StaticBackground from '../staticbg'
import Container from "../containers";
import NavBar from '../navbar'


const TermsAndConditions = () => {
    function RenderContent(key) {
        if (key === "title") 
            return null

        const content = data[key]
        if (key === "limitations")
            return <Limitations content={content}/>
        return <ContentSection title={content.title} content={content.description}/>
    }

    return (
        <StaticBackground id="terms">
            <NavBar bgColor="dark" btnColor="health" noBanner={true}/>

            <Container type='box' pos="w-maxium-9 auto-margin fstxt-s">
                <h1 className="p-1 fstitle-m papyrus">{data.title.toUpperCase()}</h1>
                {Object.keys(data).map(key => (
                    RenderContent(key)
                ))}
            </Container>

            <CopyRight txtClass="ctxt-dark"/>
            <Accessibility bgClass="dark" className="w-maxium-9 auto-margin ctxt-primary p-1"/>
        </StaticBackground>
    )
}

// Sections
const Limitations = ({ content }) => {
    return (
        <Container type="box">
            <SubHeading>{content.title}</SubHeading>
            <Content>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</Content>

            <ContentList content={content.description} listclass="no-bullets ph5" itemclass="pb1"/>
        </Container>
    )
}

export default TermsAndConditions