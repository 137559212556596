

const StaticBackground = ({ id, children }) => {
    return (
        <div id={id} className="static-bg">
            <div style={{backgroundColor: 'rgba(253, 253, 253, 0.9)'}}>
                {children}
            </div>
        </div>
    )
}

export default StaticBackground