import body from '../data/banners/body.jpg'
import health from '../data/banners/health.jpg'
import life from '../data/banners/life.jpg'
import mind from '../data/banners/mind.jpg'
import skin from '../data/banners/skin.jpg'


const Banner = (props) => {  
    const banners = { 
        "body": body, "health": health,
        "life": life, "mind": mind, "skin": skin
    }  
    const { type, className } = props

    var banner = ""
    if (type in banners) { banner = banners[type] }

    function CreateBanner() {
        return (
            <div className={`${type} relative banner max-width ${className}`}>
                <a href="/" className="max-width flex max-height justify-center">
                    <img className="banner fixed max-srceen-height" src={banner} alt="banner" />
                </a>
            </div>
        )
    }

    if (banner) return CreateBanner()
    return <div></div>
}

export default Banner