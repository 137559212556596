import React, { useState, useEffect } from 'react';


export const CopyRight = ({bgClass, txtClass}) => {
    return (
        <div className={`txt-center p-1 ${bgClass} ${txtClass}`}>
            <p>
                © T.A.M.E. TIME.® All Rights Reserved. Web Design & Internet Marketing by Swoofz
            </p>
            <a href="/policy" className={`p-1 ${txtClass}`}>Privacy Policy</a>
            <a href="/terms" className={`p-1 ${txtClass}`}>Terms & Conditions</a>
        </div>
    )
}

export const Accessibility = ({ bgClass, className }) => {
    // TODO: Make this into a box with a bg color

    return (
        <div className={`txt-center max-width fstxt-xs ${bgClass}`}> 
            <p className={className}><b>ACCESSIBILITY STATEMENT:</b> At T.A.M.E. TIME, we are dedicated to ensuring that our website is accessible to all users, regardless of their abilities or circumstances. We strive to comply with the Web Content Accessibility Guidelines (WCAG 2.0, Level AA) set by the World Wide Web Consortium (W3C), which aim to enhance web accessibility for individuals with disabilities. While we make every effort to follow these guidelines, achieving full compliance across all areas of the site can be challenging. As our website is frequently updated, minor issues may occasionally arise. We are committed to continuously improving accessibility and are actively working to address any issues.<br/>
            If you have any feedback or suggestions for improving the accessibility of our website, please contact our accessibility coordinator at tame.time@outlook.com. Your input is invaluable in helping us enhance our site.</p>
        </div>
    )
}

export const Title = (props) => {
    const { type, children } = props

    return (
        <div>
            <h1 className={`papyrus fstitle-l txt-center ctxt-${type} pt2`}>T.A.M.E. {type}</h1>
            <h3 className={`fstitle-m txt-center ctxt-${type} pb2`}>{children}</h3>
        </div>
    )
}

export const Card = (props) => {
    const { maxWidth, color, children, className } = props

    return (
        <div className={`${color} ctxt-primary p-1 border-radius-sm box-shadow-3 content-center ${className}`} style={{maxWidth: maxWidth + "px"}}>
            {children}
        </div>
    )
}

export const ContentList = ({ content, listclass, itemclass }) => {
    return (
        <ul className={listclass}>
            {content.map((item, index) => (
                <li key={index} className={itemclass}>
                    <strong dangerouslySetInnerHTML={{__html: item[0]}}/> <span dangerouslySetInnerHTML={{__html: item[1]}}/>
                </li>
            ))}
        </ul>
    )
}

export const SubHeading = ({ children }) => {
    return <h3 className="p-1 papyrus fstxt-l">{children}</h3>
}

export const Content = ({ children, className }) => {
    return <p className={`p-1 ${className}`} dangerouslySetInnerHTML={{__html: children}}/>
}

export const ContentSection = ({ title, content }) => {
    return (
        <div>
            <SubHeading>{title}</SubHeading>
            {content.map((c, index) => (
                <Content key={index}>{c}</Content>
            ))}                
        </div>
    )
}

export const Items = ({ items, className }) => {
    return items.map((item, i) => (
        <p key={i} className={className}>{item}</p>
    ))
}

export function ConvertForContentList(items) {
    return items.map(item => {
        return ["", item]
    })
}