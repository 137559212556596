import data from '../../data/page-content/health.json'
import Container from "../containers"
import { Title, CopyRight, ContentList, Accessibility, 
    Items, ConvertForContentList } from "../const"
import BookNow from "../Book"
import NavBar from '../navbar'



const Health = ({ images }) => {
    const oligoImages = {
        logo: images.oligo,
        test: images.ogoTest,
        review: images.ogoReview
    }

    return (
        <div id="health" className="ctxt-health fstxt-s">
            <NavBar bgColor="health" btnColor="skin"/>

            <Hero image={images.bed} content={data.hero}/>
            <FunctionalMedicine image={images.tamepak} content={data.functional}/>
            <OligoScan images={oligoImages} content={data.oligo}/>
            <Nexi image={images.nexi} content={data.nexi}/>
            <Acupuncture image={images.acupuncture} content={data.acupuncture}/>
            <Herbs image={images.herbs} content={data.herbs}/>
            <Cupping image={images.cupping} content={data.cupping}/>
            <Aromatherapy image={images.aromatherapy} content={data.aroma}/>
            <Mat image={images.mat} content={data.healing}/>
            
            <BookNow 
                txtColor={"ctxt-health"} cardColor={"health"} 
                btnColor={"#176485"} bgColor={"health-light"}
            />
            <CopyRight bgClass="health" txtClass="ctxt-primary"/>
            <Accessibility bgClass="health" className="auto-margin ctxt-primary p-1"/>
        </div>
    );
}

// Page Sections
const Hero = ({ image, content }) => {
    return (
        <section id="hero" className="ph5">
            <Title type="health">{content.subtitle}</Title>

            <div className="grid gap-1 col-2">
                <div className="content-center grid gap-1">
                    <p>{content.description}</p>

                    <p>{content.list.title}</p>
                    <ContentList
                        content={ConvertForContentList(content.list.items)}
                        listclass="ph5"
                    />
                </div>
                <img className="auto-margin h-maxium-4" src={image} alt="bed" />
            </div>
        </section>
    )
}

const FunctionalMedicine = ({ image, content }) => {
    const { principles, combanation } = content

    function CombineBenefits({ items }) {
        return (
            <div className="grid gap-1 col-3 p-1">
                {items.map((content, index) => (
                     <Container type="box" bg={[41, 97, 121]} alpha={0.3}
                        className="border-radius-sm max-height content-center"
                        key={index}
                    >
                        <h2 className="txt-center fstxt-m pb1 papyrus">{content[0]}</h2>
                        <p>{content[1]}</p>
                    </Container>
                ))}
            </div>
        )
    }

    return (
        <section id="functional" className="health-light mt3 p-1">
            <STitle className="ml1 pt1 pr2 mb1">{content.title}</STitle>
            <p className="p-2">{content.description}</p>

            <Container
                type='box'
                className="grid gap-1 col-2"
            >
                <img src={image} alt="functional medicane pack" className="auto-margin w-maxium-4 max-width p-1"/>
                <div>
                    <h2 className="fstxt-m pt2 papyrus">{principles.title}</h2>
                    <ContentList content={principles.items}
                        listclass="no-bullets pt2 ph5" itemclass="pb1"
                    />
                </div>
            </Container>

            <Container type='box'>
                <h2 className="underline pv2r fstxt-l papyrus">{combanation.title}</h2>
                <Items items={combanation.description} className="pv2r"/>
                <CombineBenefits items={combanation.items}/>
                <p>{combanation.conclusion}</p>
            </Container>
        </section>
    )
}

const OligoScan = ({ images, content }) => {
    return (
        <section id="oligoscan" className="">
            <div className="grid gap-1 col-2">
                <Container pos="col-start-2" type="box">
                    <img src={images.logo} alt="oligoscan logo" className="block center"/>

                    <div className="ph5">
                        <p className="pt2">{content.description}</p>
                        <p className="bold pt2 fstxt-m papyrus">{content.list.title}</p>
                        <ContentList content={content.list.items} listclass="ph5 pt1"/>
                        <p className="pt2">{content.list.description}</p>
                    </div>
                </Container>

                <div className="relative row-start-1">
                    <img src={images.test} alt="oligoscan pictures" className="max-width max-height"/>
                    <img src={images.review} alt="oligoscan pictures" style={{ position: 'absolute', bottom: 10, right: 10,  maxWidth: '50%' }} />
                </div>
            </div>
        </section>
    )
}

const Nexi = ({ image, content }) => { 
    const { why, how, science } = content

    return (
        <section id="nexi" className="health-light">
            <STitle alpha={0}>
                {content.title} <span className="fstxt-s italic"> {content.subtitle}</span>
            </STitle>

            <Container type="box" className="grid col-2">
                <p className="col-span-2 pb2" dangerouslySetInnerHTML={{__html: content.description}}/>

                <div className="content-center max-width max-height">
                    <p className="bold pt1">{why.title}</p>
                    <ContentList content={why.items} listclass="ph5 pt1" itemclass="pb1"/>
                </div>
                
                <div className="content-center max-width max-height">
                    <img src={image} width="100%" className="auto-margin"/>
                    <p className="bold pt1">{how.title}</p>
                    <ContentList content={how.items} listclass="ph5 pt1" itemclass="pb1"/>
                </div>
            </Container>

            <Container type="box" className="grid col-2 gap-1">
                <p className="bold fstxt-m papyrus col-span-2 pb1">{science.title}</p>
                <Container type="box" className="border-radius-sm max-height content-center" pos="mw-8 max-height auto-margin" bg={[41, 97, 121]} alpha={0.3}>
                    <p>{science.stress.title}</p>
                    <ContentList content={science.stress.items} listclass="no-bullets ph3 pt1" itemclass="pb1"/>
                    <p dangerouslySetInnerHTML={{__html: science.stress.description}}/>
                </Container>

                <Container type="box" bg={[41, 97, 121]} alpha={0.3} className="border-radius-sm max-height content-center" pos="mw-8 max-height auto-margin">
                    <p className="bold pt1">{science.benefits.title}</p>
                    <ContentList content={science.benefits.items} listclass="no-bullets ph3 pt1" itemclass="pb1"/>
                    <p className="bold pt1">⚠️ Disclaimer</p>
                    <p className="pt1" dangerouslySetInnerHTML={{__html: science.benefits.description}}/>
                </Container>
            </Container>

            <Container type="box" className="pt1">
                <Items items={content.conclusion} className="bold"/>
            </Container>
        </section>
    )
}

const Acupuncture = ({ image, content }) => {
    const { healing } = content

    return (
        <section id="acupuncture" className="bg-img grid gap-1 p-1 mt3"
            style={{ backgroundImage: `url(${image})` }}
        >
            <STitle className="grid justify-end pt1 pr2">{content.title}</STitle>

            <Container type="circle" alpha={0.6} hasGradient={true}
                className="half-border-radius wfit-content w-maxium-6 p-7"
            >
                <div className="wfit-content">
                    <p className="txt-center p-1 fstxt-m"><b className="papyrus">{healing.title}</b></p>
                    <p className="txt-center">{healing.description}</p>
                    <ContentList listclass="no-bullets ph2 pt1" content={healing.items}/>
                    <p className="pt2 ph2 pb1">{healing.list.title}</p>
                    <ContentList content={healing.list.items} listclass="no-bullets ph5"/>
                    <p className="txt-center p-1">{healing.list.conclusion} </p>
                </div>
            </Container>

            <Container alpha={0} type="emptybox" 
                pos="grid justify-end" className="w-maxium-6"
            >
                <p className="pb1 fstxt-m"><strong>{content.subtitle}</strong></p>
                <p>{content.conclusion}</p>
            </Container>
        </section>
    )
}

const Herbs = ({ image, content }) => {
    const { how, formulas } = content

    return (
        <section id="herbs" className="health-light mt3">
            <STitle className="ml1 pt1 pr2 mb1">{content.title}</STitle>

            <div className="grid gap-1 col-2">
                <Container type='box' pos="herb-info">
                    <p className="pb1 fstxt-m"><b className='papyrus'>{content.subtitle}</b></p>
                    <p>{content.description}</p>
                </Container>

                <img
                    className="auto-margin max-width p-1 herb-info"
                    src={image} alt="herbs"
                />

                <Container type="box" pos="herb-info row-span-2">
                    <p className="pb1"><b>{how.title}</b></p>
                    <ContentList listclass="ph5" content={how.items}/>

                    <p className="pb1 pt1"><b>{formulas.title}</b></p>
                    <p>{formulas.description}</p>
                    <ContentList listclass="ph5 pt1 pb1" content={formulas.items}/>

                    <p>{content.conclusion}</p>
                </Container>
            </div>
        </section>
    )
}

const Cupping = ({ image, content }) => {
    const { benefits, heal, caution } = content

    return (
        <section id="cupping" className="grid gap-1 p-1 col-2-3r mt2">
            <div className="cupping-info">
                <STitle className="ml1 pt1 pr2 mb1 grid justify-center" color={[41, 97, 121]} alpha={0.1}>{content.title}</STitle>
                {/* Under title */}

                <div className="grid justify-center auto-margin">
                    <p className="pt1"><b>{benefits.title}</b></p>
                    <ContentList listclass="ph3 pt1" content={benefits.items}/>
                </div>
            </div>

            {/* Side info */}
            <Container className="border-radius-sm max-height content-center"
                type="box" bg={[41, 97, 121]} alpha={0.3} pos="cupping-info"
            >
                <p className="fstxt-m"><b className="papyrus">{heal.title}</b></p>
                <p className="pt1">{heal.description}</p>

                <div className="health mt2 mw-8 border-radius-sm" style={{padding:"1px"}}></div>
                <p className="pt2 pb1"><b>{heal.subtitle}</b></p>
                <p>{heal.conclusion}</p>
            </Container>

            {/* Bottom */}
            <div className="cupping-info col-span-2">
                <img src={image} alt="bed"
                    className="auto-margin max-width p-1"
                />
                <p><b>{caution.title}</b></p>
                <ContentList listclass="ph5 pt1 pb1" content={caution.items}/>
                <p>{content.conclusion}</p>
            </div>
        </section>
    )
}

const Aromatherapy = ({ image, content }) => {
    const { help, benefits} = content

    return (
        <section id="aromatherapy" className="p-1 mt2 health-light">
            <Container type="box" className="aro circle-back">
                <h2 className="relative fstxt-l pb1 papyrus" style={{background: "none"}}>
                    {content.title}<span className="fstxt-s italic"> {content.extTitle}</span>
                </h2>
                <p className="fstxt-m"><b className="papyrus">{content.subtitle}</b></p>
                <p>{content.description}</p>
            </Container>

            <div className="grid gap-1 col-2">
                <img src={image} alt="herbs"
                    className="max-width p-1 half-border-radius auto-margin"
                />

                <div className="row-span-2">
                    <Container type="box"  bg={[41, 97, 121]} alpha={0.3}
                    pos="auto-margin w-maxium-4 m-1a" className="border-radius-sm content-center">
                        <p><b>{benefits.title}</b></p>
                        <ContentList listclass="no-bullets bold pt1 pl2" content={benefits.items}/>
                    </Container>

                    <Container type="box" bg={[41, 97, 121]} alpha={0.3} 
                        pos="auto-margin w-maxium-25 m-1a"
                        className="border-radius-sm max-height content-center"
                    >
                        <p className="bold">{help.title}</p>
                        <ContentList listclass="no-bullets bold pt1 pl2" content={help.items}/>
                    </Container>
                </div>

                <Container type="box">
                    <p className="aro-footer" dangerouslySetInnerHTML={{__html: content.conclusion}}/>
                </Container>
            </div>
        </section>
    )
}

const Mat = ({ image, content }) => {
    const { pulsed, farInfrared, negative, hotStone} = content

    const data = [ // Title , content, className
        [pulsed.title, pulsed.items, "auto-margin row-start-3 col-start-1"], [farInfrared.title, farInfrared.items, "auto-margin col-start-1"], [negative.title, negative.items, "auto-margin"], [hotStone.title, hotStone.items, "auto-margin"],
    ]

    const Therapy = ({ title, content, className}) => {
        return (
            <Container
                type="circle" alpha={0.2}  bg={[41, 97, 121]} 
                pos={className} className="w-maxium-4 p-1 border-radius-sm"
            >
                <p className="fstxt-m pb1">
                    <strong className="papyrus">{title}</strong>
                </p>
                <ContentList listclass="ph5" content={content}/>
            </Container>
        )
    }



    return (
        <section id="mat" className="bg-img p-1 mt3">
            <div className="grid gap-1 col-3">
                <STitle className="ml1 pt1 pr2 mb1 col-span-3">
                    {content.title}<span className="fstxt-s italic"> {content.extTitle}</span>
                </STitle>
                <img className="auto-margin mw-8 col-span-2 border-radius-sm" src={image} alt="mat" />
                <h3 className="col-span-3 txt-center row-start-2 fstxt-m papyrus">{content.subtitle}</h3>

                {data.map((d, index) => (
                    <Therapy key={index}
                        title={d[0]} className={d[2]} content={d[1]} 
                    />
                ))}

                <p className="col-span-3 txt-center">{content.conclusion}</p>
            </div>
        </section>
    )
}

// Utility
const STitle = ({color, alpha=0.8, className, children}) => {
    return (
        <Container
            alpha={alpha} type="box" pos={className}
            className="wfit-content" bg={color}
        >
            <h2 className="h2right txt-center fstxt-l papyrus">{children}</h2>
        </Container>
    )
}


export default Health;