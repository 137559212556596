import content from '../../data/page-content/about.json'
import portrait from '../../data/portrait2.JPG'
import { CopyRight, ContentList, 
    Content, SubHeading, Accessibility } from '../const'
import StaticBackground from '../staticbg'
import Container from "../containers"
import NavBar from '../navbar'


function Bio() {
    const { credentials, focus, culture} = content
    console.log(culture.title, "Culture")

    return (
        <StaticBackground id="bio" >
            <NavBar bgColor="health" btnColor="skin" noBanner={true}/>

            <Container type="box" pos="w-maxium-9 auto-margin fstxt-s ctxt-health">
                <h1 className="p-1 fstitle-l papyrus txt-center">{content.title}</h1>
                
                <div className="max-width grid col-2 gap-1">
                    <img className="portrait auto-margin h-maxium-3 border-radius-sm box-shadow-4 mb1" src={portrait} alt={"Dr. Tatyana Picture"} />

                    <Content className="auto-margin">{content.bio}</Content>
                </div>
                
                <SubHeading>{credentials.title}</SubHeading>
                <ContentList content={credentials.items} listclass="ph5" itemclass="pb1"/>
                
                <SimpleSection content={focus}/>
                <SimpleSection content={culture}/>
            </Container>
           
           <CopyRight bgClass="health" txtClass="ctxt-skin"/>
           <Accessibility bgClass="health" className="auto-margin ctxt-skin p-1"/>
        </StaticBackground>
    )
}

const SimpleSection = ({ content }) => {
    const { title, description } = content

    return (
        <div>
             <SubHeading>{title}</SubHeading>
             <Content>{description}</Content>
        </div>
    )
}


export default Bio