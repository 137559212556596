import content from '../../data/page-content/policies.json'
import { CopyRight, Content, Accessibility } from '../const'
import StaticBackground from '../staticbg'
import Container from "../containers";
import NavBar from '../navbar'


function Policy() {
    const lineStyle = {height:"2px", width:"50%", minWidth:"230px"}

    return (
        <StaticBackground id="policies">
            <NavBar bgColor="dark" btnColor="health" noBanner={true} hideForScroll={false}/>
            <Container type='box' pos="w-maxium-5 auto-margin fstxt-s">
                <h1 className="p-1 fstitle-m papyrus">{content.title}</h1>
                <div style={lineStyle} className="dark mb1"></div>
                
                {content.items.map((item, index) => (
                    <Content key={index}>{item}</Content>
                ))}
            </Container>
            <CopyRight txtClass="ctxt-dark"/>
            <Accessibility bgClass="dark" className="w-maxium-6 auto-margin ctxt-primary p-1"/>
        </StaticBackground>
    )
}


export default Policy