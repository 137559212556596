
const BodyImages = async () => {
    const bed = await import("../data/imgs/body/bed.png")
    const massage = await import("../data/imgs/body/massage.jpg")
    const oil = await import("../data/imgs/body/oil.png")
    const workout = await import("../data/imgs/body/workout.png")

    return {
        bed: bed.default,
        massage: massage.default,
        oil: oil.default,
        workout: workout.default
    }
}

const HealthImages = async () => {
    const bed = await import("../data/imgs/health/bed.jpg")
    const acupuncture = await import("../data/imgs/health/acupunture.jpg")
    const aromatherapy = await import("../data/imgs/health/aromatherapy.jpg")
    const cupping = await import("../data/imgs/health/cupping.jpg")
    const herbs = await import("../data/imgs/health/herbs.jpg")
    const mat = await import("../data/imgs/health/mat.jpg")
    const tamepak = await import("../data/imgs/health/tamepak.jpeg")
    const oligo = await import("../data/imgs/health/oligo.jpeg")
    const ogoTest = await import("../data/imgs/health/oligo_test.jpeg")
    const ogoReview = await import("../data/imgs/health/oligo_review.png")
    const nexi = await import("../data/imgs/health/nexi.png")

    return {
        bed: bed.default,
        acupuncture: acupuncture.default,
        aromatherapy: aromatherapy.default,
        cupping: cupping.default,
        herbs: herbs.default,
        mat: mat.default,
        tamepak: tamepak.default,
        oligo: oligo.default,
        ogoTest: ogoTest.default,
        ogoReview: ogoReview.default,
        nexi: nexi.default
    }
}

const LifeImages = async () => {
    const grocery = await import("../data/imgs/life/grocery.jpg")
    const mealPrep = await import("../data/imgs/life/mealPrep.jpg")
    const meals = await import("../data/imgs/life/meals.jpg")

    return {
        grocery: grocery.default,
        mealPrep: mealPrep.default,
        meals: meals.default,
    }
}

const MindImages = async () => {
    const mindsetF = await import("../data/imgs/mind/mindset.jpg")
    const mindset = await import("../data/imgs/mind/mindset2.jpg")
    const mindsetA = await import("../data/imgs/mind/mindset2.png")

    return {
        mindsetF: mindsetF.default,
        mindset: mindset.default,
        mindsetA: mindsetA.default,
    }
}

const SkinImages = async () => {
    const light = await import("../data/imgs/skin/light.jpg")
    const lightshutterF = await import("../data/imgs/skin/lightshutterstock.jpg")
    const lightshutterA = await import("../data/imgs/skin/lightshutterstock.png")
    const mircocurrent = await import("../data/imgs/skin/mircocurrent.jpg")
    const mircoDerm = await import("../data/imgs/skin/mircoDerm.jpg")
    const room = await import("../data/imgs/skin/n_room.jpg")
    const shutterstock = await import("../data/imgs/skin/shutterstock.jpg")
    const skincare = await import("../data/imgs/skin/skincare.jpg")
    const needling = await import("../data/imgs/skin/microneedling.jpeg")
    const effects = await import("../data/imgs/skin/effects.jpeg")

    return {
        light: light.default,
        lightshutterF: lightshutterF.default,
        lightshutterA: lightshutterA.default,
        mircocurrent: mircocurrent.default,
        mircoDerm: mircoDerm.default,
        room: room.default,
        shutterstock: shutterstock.default,
        skincare: skincare.default,
        needling: needling.default,
        effects: effects.default,
    }
}


const LoadImages = async (type) => {
    const libery = {
        "body": BodyImages, "health": HealthImages, "life": LifeImages,
        "mind": MindImages, "skin": SkinImages
    }

    if (type in libery) return libery[type]()
}


export default LoadImages
// Body - bed, massage, oil, workout
// Health - bed, acupuncture, aromatherapy, cupping, herbs, mat, tamepak, oligo, ogoTest, ogoReview, nexi
// Life - grocery, mealPrep, meals
// Mind - mindsetF, mindset, mindsetA
// Skin - light, lightshutterF, lightshutterA, mircocurrent, mircoDerm, room, shutterstock, skincare, needling, effects