import React, { useState, useEffect } from 'react';
import logo from '../data/logo-title.png'
import { Card } from './const'

const NavBar = ({ bgColor, btnColor, noBanner, hideForScroll=true }) => {
    const [isShown, setIsShown] = useState('hide')
    const [isMobileView, setMobileView] = useState(false)
    const size = parseFloat(getComputedStyle(document.documentElement).fontSize) * 40 // For 40 Rem media in the CSS file

    useEffect(() => {
        window.addEventListener('resize', HandleResize)
        window.addEventListener('scroll', showNav)
        HandleResize()
        showNav()
    }, [])

    const HandleResize = () => {
        window.innerWidth < size ? setMobileView(true) : setMobileView(false)
    }

    const showNav = () => {
        if (!hideForScroll) setIsShown("show")
        else if (window !== undefined) {
            let windowHeight = window.scrollY
            windowHeight > 10 ? setIsShown("show") : setIsShown('hide')
        }
    }

    let CSSWidthClass = ""
    if (noBanner) CSSWidthClass="max-srceen-width"

    if(isMobileView) 
        return <MobileNavBar bgColor={bgColor} btnColor={btnColor} className={`fixed ${bgColor} max-srceen-height half-srceen-width`}/>

    if (!hideForScroll)
        return (
            <div className="navbar">
                <Content btnColor={btnColor} className={`grid ${bgColor} ${isShown} ${CSSWidthClass}`} isMobileView={false}/>
            </div>
        )

    return ( 
        <div className="fixed navbar">
            <Content btnColor={btnColor} className={`grid ${bgColor} ${isShown} ${CSSWidthClass}`} isMobileView={false}/>
        </div>
    )
}

export const MobileNavBar = ({ btnColor, className }) => {
    const [slideAnin, setSlideAnim] = useState("full-right slideIn")
    const [cover, setCover] = useState("none")
    
    const Slide = () => {
        if (slideAnin === "full-right slideIn") {
            setSlideAnim("half-right slideOut")
            setCover("block")
        } else {
            setSlideAnim("full-right slideIn")
            setCover("none")
        }
    }

    console.log("Sad:", cover, slideAnin)

    return (
        <div>
            <div className="fixed max-srceen-width max-srceen-height" style={{background: "rgba(0,0,0,0.7)", zIndex: 10, display: cover}}></div>
            <div className={`${className} ${slideAnin}`} style={{zIndex:10}}>
                <Content btnColor={btnColor} className="inline-block line-height-4" isMobileView={true}/>
                <MobileNavButton bgColor={btnColor} Action={Slide}/>
            </div>
        </div>
    )
} 

const ActionButtons = ({ color, className, style={} }) => {
    return (
        <div className={className} style={style}>
            <a href="https://squareup.com/gift/G1FQ2PM9NQSRK/order">
                <Card color={color} className="txt-center hover fstxt-s m-1">
                    <span>Gift Card</span>
                </Card>
            </a>

            <a href="https://squareup.com/gift/G1FQ2PM9NQSRK/order">
                <Card color={color} className="txt-center hover fstxt-s m-1">
                    <span>Book Now</span>
                </Card>
            </a>
        </div>
    )
}

const Content = ({ btnColor, className, isMobileView }) => {
    return (
        <div className={className}>
            <Logo className="content-center p-1"/>
            <Links className="content-center p-1 align-center"/>

            { !isMobileView ? ( 
                <ActionButtons color={btnColor} className="align-center pr2 flex justify-end"/>
            ): ( 
                <ActionButtons color={btnColor} className="absolute max-width p-2" style={{bottom:10}}/>
            )}
        </div>
    )
}

const Links = ({ className }) => {
    return (
        <div className={className}>
            <div className="flex gap-1 txt-center fsnav">
                <a href="/"><span className="ctxt-primary block">Home</span></a>
                <a href="/health"><span className="ctxt-primary block">Health</span></a>
                <a href="/mind"><p className="ctxt-primary block">Mind</p></a>
                <a href="/body"><p className="ctxt-primary block">Body</p></a>
                <a href="/life"><p className="ctxt-primary block">Life</p></a>
                <a href="/skin"><p className="ctxt-primary block">Skin</p></a>
                <a href="/about"><p className="ctxt-primary">About</p></a>
            </div>
        </div>
    )
}

const Logo = ({ className }) => {
    return (
        <div className={className}>
            <a href="/"><img src={logo} alt="T.A.M.E.@T.I.M.E." className="max-width max-height"/></a>
        </div>
    )
}

const MobileNavButton = ({ bgColor, Action }) => {
    return (
        <div className="fixed inline-block" style={{width:"20px", height:"17px", margin:"10px"}} onClick={Action}>
            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px"}}></div>
            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px", marginTop: "1px"}}></div>
            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px", marginTop: "1px"}}></div>
        </div>
    )
}

export default NavBar