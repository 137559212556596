
import data from "../../data/page-content/body.json"
import { Card, CopyRight, Title, Accessibility, 
    ContentList, ConvertForContentList } from "../const"
import Container from "../containers";
import NavBar from '../navbar'


const Body = ({ images }) => {
    const bodyWorkImgs = {"oil":images.oil, "massage":images.massage}

    return (
        <div id="body" className="ctxt-body fstxt-s">
            <NavBar bgColor="body" btnColor="skin"/>

            <Hero image={images.bed} content={data.hero}/>
            <BodyWork images={bodyWorkImgs} content={data.aromatherapeutic}/>

            <CopyRight bgClass="body" txtClass="ctxt-primary"/>
            <Accessibility bgClass="body" className="auto-margin ctxt-primary p-1"/>
        </div>
    )
}

const Hero = ({ image, content }) => {
    const list = ConvertForContentList(content.items)

    return (
        <section id="hero" className="ph5">
            <Title type="body"></Title>

            <div className="grid gap-1 col-2">
                <div className="auto-margin">
                    <p>{content.description}</p>
                    <ContentList content={list}/>
                </div>
                
                <div style={{backgroundImage: `url(${image})`}} className="bg-img grid content-center h-minium-4"></div>
                <p className="col-span-2">{content.conclusion}</p>
            </div>
        </section>
    )
}

const BodyWork = ({ images, content }) => {
    const { list } = content
    const items = ConvertForContentList(list.items)

    return (
        <section id="aromatherapeutic" className="body-light grid gap-1 col-2 p-2">
            <Container type="box" className="txt-center mt3" alpha={0.8} pos="col-span-2">
                <h1 className="fstxt-l papyrus">{content.title}</h1>
            </Container>

            <img src={images.oil} alt="oils" className="auto-margin"/>

            <Container type="box" alpha={0.9} pos="justify-center"  className="border-radius-sm mw-8 auto-margin">
                <p className="p-1" dangerouslySetInnerHTML={{__html: content.description}}/>
            </Container>

            <img src={images.massage} alt="body work image" className="max-width auto-margin border-radius-sm"/>

            <Card color="body" className="auto-margin col-start-1 row-start-3">
                <h3 className="txt-center underline fstxt-m papyrus">{list.title}</h3>
                <ContentList content={items} listclass="open-bullets mw-8 p-1"/>
            </Card>
        </section>
    )
}


export default Body