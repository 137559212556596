import data from "../../data/page-content/skin.json"
import { Title, Card, CopyRight, Accessibility,
      ContentList, ConvertForContentList, 
      Content} from "../const"
import Container from "../containers";
import Button from "../button";
import BookNow from "../Book";
import NavBar from '../navbar'


const Skin = ({ images }) => {
   const needlingImg = {"needling": images.needling, "effects": images.effects}
   const ledImg = {"shutterstock": images.shutterstock, "light": images.light} 

    return (
     <div id="skin" className="ctxt-skin fstxt-s">
      <NavBar bgColor="skin" btnColor="health"/>

      <Hero image={images.room} content={data.hero} />
      <Needling images={needlingImg} content={data.needling} />
      <Care image={images.skincare} content={data.care} />
      <MircoCurrent image={images.mircocurrent} content={data.current} />
      <MircoDermabrasion image={images.mircoDerm} content={data.dermabrasion} />
      <LED images={ledImg} content={data.led} />

      <BookNow
         txtColor={"ctxt-skin"} 
         cardColor={"skin"} 
         btnColor={"#646464"}
      />
      <CopyRight bgClass="skin" txtClass="ctxt-primary"/>
      <Accessibility bgClass="skin" className="auto-margin ctxt-primary p-1"/>
     </div>
    );
  }


const Hero = ({ image, content }) => {
   const { list } = content;
   const items = ConvertForContentList(list.items);

   return (
      <section id="hero" className="">
         <Title type="skin" className="ph5">{content.title}</Title>

         <div style={{backgroundImage: `url(${image})`}} className="ph5 ctxt-primary pv8 grid gap-1 col-2 bg-img">
            <Card color="skin" className="txt-center col-start-2" maxWidth={500}>
               <p>{list.description}</p>
               <ContentList content={items} listclass="no-bullets pt1"/>

               {/* Book Now Button */}
               <div className="pt1 uppercase underline">
                  <a href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&LocationUid=76e4c4ba-c15f-4d19-974d-13691e39f372"><p>
                     Book Now!
                  </p></a>
               </div>
            </Card>

            <p className="col-span-2 pt2" style={{width: "80%", margin: "auto"}}>{content.conclusion}</p>
         </div>         
      </section>
   )
}

const Needling = ({ images, content }) => {

   return (
      <section id="mirconeedling">
         <Container type="box" className="grid gap-1 col-2">
            <div>
               <h2 className="mt3 pb6 fstxt-l papyrus">{content.title}</h2>
               <div className="skin mt2 mw-8 border-radius-sm" style={{padding:"1px"}}></div>
               <p className="mt1">{content.description}</p>
            </div>

            <img src={images.needling} alt="picture" className="max-width half-border-radius h-maxium-4 w-maxium-4 auto-margin"/>
         </Container>
         
         <Container type="box" className="grid gap-1 col-2 mt2">
            <ContentList content={content.items} listclass="no-bullets auto-margin"/>
            <img src={images.effects} alt="Before and After" className="max-width col-start-1 row-start-1 pb2"/>
         </Container>        
      </section>
   )
}

const Care = ({ image, content }) => {
   const { list } = content

   return (
      <section id="care" className="ph5 skin-light grid gap-1 col-2 pt1">
         <img className="auto-margin max-width w-maxium-4 border-radius-sm col-start-2 row-span-2" src={image} alt="Facial picture" />

         {/* Top Image */}
         <Container type="box" pos="row-start-1 auto-margin" className="txt-center">
             <h3 className="fstxt-l papyrus">{content.title}</h3>
             <h2 className="fstxt-m papyrus">{content.subtitle}</h2>

             {/* Book Now button */}
             <Button href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&LocationUid=76e4c4ba-c15f-4d19-974d-13691e39f372" color="skin">Book Now!</Button>
         </Container>

         <Card color="skin">
            <h4 className="fstxt-m papyrus">{list.title}</h4>
            <p className="pt1">{list.description}</p>
            <ContentList content={list.items} listclass="p-2"/>
            <p>{list.conclusion}</p>  
         </Card>
      </section>
   )
}

const MircoCurrent = ({ image, content }) => {
   const { list, closure } = content
   const items = ConvertForContentList(list.items);

   return (
      <section id="microcurrent" className="mt3 grid gap-1 col-3">
         <Container type="box" alpha={0.8} pos="grid pt1 justify-left col-span-2 ml1 align-center ctxt-primary" bg={[166,166,166]}>
            <h2 className="fstxt-l papyrus">{content.title}</h2>
         </Container>

         <Container type="emptybox" alpha={0.2} pos="row-span-3 auto-margin w-maxium-4" className="ctxt-dark box-shadow-3 border-radius-sm p-1" style={{maxHeight: "90%"}} bg={[166,166,166]}>
               <p className="pb2">{content.description}</p>
               <h4 className="pb1">{content.subtitle}</h4>
               <p>{content.results}</p>
         </Container>

         <Card className="skin wfit-content w-maxium-4 ctxt-primary auto-margin">
            <h4 className="pb1 fstxt-m papyrus">{list.title}</h4>
            <p>{list.description}</p>
            <ContentList content={items} listclass="no-bullets pt1"/>
         </Card>

         <img src={image} alt="" className="max-width col-start-1 row-start-2 auto-margin border-radius-sm p-1" style={{height: "300px"}}/>

         <div className="col-span-2">
            {/* Small box */}
            <Container type="box" pos="">
               <h4 className="pb1">{closure.title}</h4>
               <ContentList content={closure.items} listclass="ph5 pt1 pb1"/>
               <p>{closure.conclusion}</p>
            </Container>

            {/* Warning Text */}
            <Container type="box">
               <p style={{fontSize: "12px",textAlign: "left", marginTop: "10px"}} dangerouslySetInnerHTML={{__html:closure.warning}}/>
            </Container>
         </div>
      </section>
   )
}

const MircoDermabrasion = ({ image, content }) => {
   const { list } = content
   const items = ConvertForContentList(list.items)

   return ( 
      <section id="microDermabrasion" className="bg-img grid gap-1 p-1 mt3"
            style={{ backgroundImage: `url(${image})` }}>
            
            <Container type="box" alpha={0.8} pos="grid justify-end pt1 pr2">
               <h2 className="fstxt-l papyrus w-minium-5">{content.title}</h2>
            </Container> 

            <Container type="box" alpha={0.8} pos="grid justify-end pt1 pr2" className="w-maxium-5">
               <h4 className="pb1 underline">{content.subtitle}</h4>
               <p className="pv2r">{content.description}</p>
               <h4 className="pt1">{list.title}</h4>
               <ContentList content={items} listclass="no-bullets ph2 pb2"/>
            </Container>

           <p className="txt-center ctxt-primary bold fstxt-m">{content.conclusion}</p>
      </section>
   )
}

const LED = ({ images, content }) => {
   const { light, cosmetic } = content
   const lItems = ConvertForContentList(light.items)
   const cItems = ConvertForContentList(cosmetic.items)

   return (
      <section id="led" className="skin-light p-1 grid gap-1 col-2">
         <div className="pt2">
            <h2 className="fstxt-l papyrus">{light.title}</h2>
            <p className="pt2">{light.description}</p>
            <ContentList content={lItems} listclass="no-bullets ph2 pt1"/>

            <h4 className="pt1">{light.benfiets.title}</h4>
            <ContentList content={light.benfiets.items} listclass="no-bullets ph2 pt1"/>
          
            <p className="pt2">{light.conclusion}</p> {/* conclusion */}
         </div>

         <img src={images.shutterstock} alt="light photo" className="max-width p-1 auto-margin"/>

         <div className="">
            <h3 className="fstxt-l pb1 papyrus">{cosmetic.title}</h3>
            <p>{cosmetic.description}</p>
            <ContentList content={cItems} listclass="no-bullets bold ph2 pt1"/>

            <h4 className="pt2">{cosmetic.advantages.title}</h4>
            <ContentList content={cosmetic.advantages.items} listclass="ph5 p-1"/>
            <p dangerouslySetInnerHTML={{__html:cosmetic.conclusion}}/>     
         </div>

         <img src={images.light} alt="light photo" className="max-width p-1 h-maxium-4 auto-margin col-start-1 row-start-2"/>

         <div className="col-span-2 pb2">
            <p className="txt-center bold fstxt-m">{content.conclusion}</p>
         </div>
      </section>
   )
}
  
export default Skin;