import { Card } from "./const"


const LOCATIONS = [ // [ Location, About location ]
    ["Beverly Hills", "9730 Wilshire Blvd, STE 107"],
    ["Bakersfield", "8501 Camino Media, Suite 100"],
    ["Virtual Care", "Discovery, Skincare, Consultaion,\nTeleHealth, TeleMedicine, etc."]
]

const BookNow = ({ txtColor, cardColor, btnColor, bgColor }) => {
    return (
        <section id="book-appointment" className={bgColor}>
            <div className="auto-margin mw-8">
                <h2 className={`underline txt-center pt2 ${txtColor} fstxt-l papyrus`}>Book Now With Us</h2>

                <div className="grid txt-center justify-center gap-1 col-3 pt2 fstxt-s">
                    {LOCATIONS.map((location, index) => (
                        <Card color={`${cardColor}`} maxWidth={300} style={{fontSize:"30px"}} key={index}>
                            <Location location={location}/>
                            <BookButton style={{background: btnColor, borderColor: "black"}} />
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    )
}

const Location = ({ location }) => {
    const [place, info] = location
    return (
        <div>
            <h3>{place.toUpperCase()}</h3>
            <p>{info}</p>
        </div>
    )
}

const BookButton = ({ style }) => {
    return (
        <a id="book_button" className="up-green-btn btn-large mt1" href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&amp;LocationUid=5f2934a6-ffed-4721-8a1b-bec5872ac4ac" target="_blank" style={style}>
            <span id="up_list">Book Appointment</span>
        </a>
    )
}

export default BookNow