import content from "../../data/page-content/life.json";
import { Title, CopyRight, Accessibility,
    ContentList, ConvertForContentList } from "../const";
import bg from "../../data/bg-Logo.png"
import Button from "../button";
import NavBar from '../navbar'


const Life = ({ images }) => {
    return (
        <div id="life" className="ctxt-life fxtxt-s">
            <NavBar bgColor="life" btnColor="health"/>

            <div id="life" className="max-srceen-height bg-img" style={{backgroundImage: `url(${bg})`}}>
                <section id="hero" className="ph5 light-bg content-center mh-95">
                    <Title type="life">{content.life.title}</Title>

                    <div className="grid gap-1 p-1 col-3 mt3">
                        <img src={images.grocery} alt="" className="max-width" />
                        <TBABox content={content.life.grocery}/>
                        <img src={images.mealPrep} alt="" className="max-width" />
                        <TBABox content={content.life.mealprep}/>
                        <img src={images.meals} alt="" className="max-width" />
                        <TBABox content={content.life.meals}/>
                    </div>
                </section>
            </div>

            <CopyRight bgClass="life" txtClass="ctxt-primary"/>
            <Accessibility bgClass="life" className="auto-margin ctxt-primary p-1"/>
        </div>
    )
}


const TBABox = ({ content }) => {
    const data = ConvertForContentList(content)

    return (
        <Button color="life" className="auto-margin txt-center ctxt-primary w-maxium-3 fstxt-m" pos="content-center">
            <ContentList content={data} listclass="no-bullets p-1"/>
        </Button>
    )
}


export default Life