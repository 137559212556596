import portrait from '../../data/portrait.jpg';
import data from '../../data/page-content/home.json';
import { Card, CopyRight, Accessibility, Items } from '../const'
import NavBar from '../navbar'
import BookNow from '../Book'


const Home = () => {
  return (
    <div id="home">
      <NavBar bgColor="skin" btnColor="health" noBanner={true}/>

      <Hero content={data.hero} />
      <About content={data.about} />
      <Services content={data.services} />

      <BookNow 
        txtColor={"ctxt-skin"} 
        cardColor={"skin"} 
        btnColor={"rgb(41, 97, 121)"}
      />

      <CopyRight txtClass="ctxt-skin"/>
      <Accessibility bgClass="skin-light" className="auto-margin ctxt-skin p-1"/>
    </div>
  );
}

// Sections
const Hero = ({ content }) => {
  return (
    <section id="hero">
        <div className="absolute max-width">
          <div className="relative uppercase txt-center ctxt-primary max-width ctxt-stroke-sm txt-shadow-1 fshero-l">
            <Items items={content.title} className='papyrus'/>
          </div>

          <div className="uppercase txt-center ctxt-primary max-srceen-width contect-center arial-narrow p-1 txt-shadow-4 ctxt-stroke-sm fadeIn-5 fshero-m w-maxium-4 health border-radius-l">
            <Items items={content.description} className="pb1"/>
          </div>
        </div>

        <video className="relative max-width" autoPlay muted src={content.video} type="video/mp4" />
      </section>
  )
}

const About = ({ content }) => {
  const description = [content.description[0], <>{content.description[1]} <LearnMore link="/about" className="uppercase fstxt-xs"/></>];

  return (
    <section id="about" className="auto-margin mw-8">
      <div className="max-width grid col-1-2">
          <img className="portrait auto-margin border-radius-sm box-shadow-4 mb1" src={portrait} alt={"Dr. Tatyana Picture"} />

          <Card color="health" maxWidth={700} className="auto-margin">
            <h2 className="underline auto-margin p-1 txt-center fstxt-l papyrus">{content.title}</h2>
            <Items items={description} className='fstxt-s pb1'/>
          </Card>
      </div>
    </section>
  )
}

const Services = ({ content }) => {
  const { health, links, skin } = content;

  const List = ({ items, className, itemClass }) => {
    return (
      <ul className={className}>
        {items.map((item, i) => (
          <li key={i} className={itemClass}>{item}</li>
        ))}
      </ul>
    )
  }

  const LearnOrBook = ({ link }) => (
    <div className="uppercase pt2">
      <LearnMore link={link} className="fstxt-xs halfw inline-block"/>
      <a href="#book-appointment" className="txt-right fstxt-xs halfw inline-block">Book Appointment</a>
    </div>
  )

  return (
    <section id="services" className="skin-light">
      <h2 className="underline txt-center pt2 ctxt-skin fstxt-l papyrus">{content.title}</h2>

      <div className="grid auto-margin mw-8 col-3 gap-1 mt1 justify-center">
        {/* HEALTH CARD */}
        <Card color="health" maxWidth={300}>
          <a href="/health" className="ctxt-primary"><h3 className="txt-center papyrus underline fstxt-m">{health.title}</h3></a>
          <p className='fstxt-s pb2'>{health.description}</p>
          <p className='fstxt-s'>{health.list.title}</p>
          <List items={health.list.items} className='grid justify-center fstxt-s'/>
          <LearnOrBook link="/health"/>
        </Card>

        {/* MIND BODY AND LIFE Links on CARD */}
        <Card color="health" maxWidth={300}>
          <ul className='txt-center bold no-bullets line-height-5 fstxt-m'>
            {links.map((link, i) => (
              <a href={`/${link.toLowerCase()}`} key={i}>
                <li className="ctxt-primary papyrus">{link}</li>
              </a>
            ))}
          </ul>
        </Card>

        {/* SKIN CARD */}
        <Card color="health" maxWidth={300}>
        <a href="/skin" className="ctxt-primary"><h3 className="txt-center papyrus underline fstxt-m">{skin.title}</h3></a>
          <p className='fstxt-s'>{skin.description}</p>
          <List items={skin.items} className='pl4 fstxt-s'/>
          <LearnOrBook link="/skin"/>
        </Card>
        
      </div>
    </section>
  )
}


// Utility Components
const LearnMore = ({ link, className }) => {
  return <a href={link} className={className}>Learn More</a>
}

export default Home;