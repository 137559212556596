import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "./pages/home"
import Pages from './Pages'


function AppRouter() {
    return (
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/body" element={<Pages type="body" />} />
            <Route path="/health" element={<Pages type="health" />} />
            <Route path="/mind" element={<Pages type="mind" />} />
            <Route path="/life" element={<Pages type="life" />} />
            <Route path="/skin" element={<Pages type="skin" />} />
            <Route path="/about" element={<Pages type="about" />} />
            <Route path="/policy" element={<Pages type="policy" />} />
            <Route path="/terms" element={<Pages type="terms" />} />
          </Routes>
        </Router>
      )
}

export default AppRouter