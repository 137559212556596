import React, { useState, useEffect } from "react"
import Banner from "./banner"
import LoadImages from "./loadImages"

// Pages using this template
import Body from "./pages/body"
import Health from "./pages/health"
import Life from "./pages/life"
import Mind from "./pages/mind"
import Skin from "./pages/skin"
import Bio from "./pages/about"
import Policy from "./pages/policies"
import TermsAndConditions from "./pages/terms"


function Pages(props) {
    const [images, setImage] = useState({})
    const { type } = props
    const pages = {
        "body": false, "health": false, "life": false,
        "mind": false, "skin": false, "about": false,
        "policy": false, "terms": false
    }
    if(type in pages) pages[type] = true
    const size = parseFloat(getComputedStyle(document.documentElement).fontSize) * 40 // For 40 Rem media in the CSS file
    const [display, setDisplay] = useState(NoBanner() || window.screen.width < size ? '' : 'grid')

    function NoBanner() { return type === 'policy' || type === 'about' || type === 'terms' }

    useEffect(() => {
        window.addEventListener('resize', HandleSizeBar)
        const fetchImages = async () => {
            const imgs = await LoadImages(type)
            setImage(imgs)
        }

        HandleSizeBar()
        fetchImages()
    }, [])

    const HandleSizeBar = () => {
        if (NoBanner) return
        window.innerWidth < size ? setDisplay('') : setDisplay('grid')
    }

    return (
        <div className={`${display} col-1-3`}>
            <Banner type={type} />

            { pages.health && <Health images={images} /> }
            { pages.skin && <Skin images={images} /> }
            { pages.body && <Body images={images} />}
            { pages.mind && <Mind images={images} />}
            { pages.life && <Life images={images} />}
            { pages.about && <Bio />}
            { pages.policy && <Policy />}
            { pages.terms && <TermsAndConditions />}
        </div>
    )
}

export default Pages